import axios from 'axios';
import queryString from 'query-string';
import Constants from './constants';
import datasetService from './datasetService';
import Utils from './utils';

class AuthService {
  constructor() {
    this.awsApiAxios = axios.create({
      baseURL: `${Constants.apiGatewayUrl}/`
    });
  }

  // Get the access, ID and refresh tokens from Ping using the authorization code
  getRefreshToken(refreshToken) {
    return this.awsApiAxios.post('refreshtoken',
      {
        refreshToken
      }).then((response) => response.data)
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        throw error;
      });
  }

  // Get the user profile from Ping using the access token
  getPingToken(code, redirectUri) {
    return this.awsApiAxios.post('token',
      {
        code,
        redirectUri
      }).then((response) => response.data)
      .catch((error) => {
        console.log(`There was an error in the call to AWS. The error is: ${error.message}`);
        throw error;
      });
  }

  _codeFlow(code) {
    // Formulate the redirect uri required for auth code flow
    const parsedQuery = queryString.parse(window.location.search);
    if (parsedQuery.code) {
      // If there's a code in the query params, delete it
      delete parsedQuery.code;
    }
    if (parsedQuery.session_state) {
      delete parsedQuery.session_state;
    }
    const query = queryString.stringify(parsedQuery);
    const redirectUri = window.location.origin + window.location.pathname + (query ? `?${query}` : '');

    // Remove code from URL
    window.history.pushState({}, '', redirectUri);

    return this.getPingToken(code, redirectUri)
      .then((response) => {
        const { accessToken } = response;

        // Store the access token and ID token in local storage
        localStorage.setItem('accessToken', accessToken);

        datasetService.setAuthToken(accessToken);

        // TODO: Figure out error handling
        return datasetService.getUserInfo()
          .then((user) => user)
          .catch((err) => err);
      })
      .catch((err) => {
        throw err;
      });
  }

  _noCodeFlow = () => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      // Ensure the token is set by dataset service
      return datasetService.getUserInfo()
        .then((user) => user)
        .catch((err) => {
          // If token is invalid, go to SSO
          if (err.response.status === 401) {
            window.location = Utils.createRedirectUrl();
          } else {
            console.log(err.response);
          }
        });
    } 
    // No token, no entry
    window.location = Utils.createRedirectUrl();
    return new Promise((resolve) => {
      resolve({});
    });
  }

  isAuthenticated() {
    const parsed = queryString.parse(window.location.search);
    const { code } = parsed;

    if (code) {
      return this._codeFlow(code);
    }

    return this._noCodeFlow();
  }
}

const authService = new AuthService();

export default authService;
